<template>
  <div>
    <div class="text-align-center">
      <img src="@/assets/images/megaport-logos/Megaport-Logo-Web-72dpi-RGB.png"
        :alt="$t('images.megaport-logo')"
        width="400"
        height="115">
    </div>
    <div class="content-panel">
      <h3>{{ $t('authentication.confirm-email-label') }}</h3>

      <p v-if="state === 'waiting'"
        class="color-warning">
        {{ $t('authentication.confirming') }}
      </p>
      <p v-if="state === 'success'"
        class="color-success">
        {{ $t('authentication.success') }}
      </p>
      <p v-if="state === 'failed'"
        class="color-danger">
        {{ $t('authentication.failed-confirm') }}
      </p>

      <el-button type="primary"
        @click="$router.push('/login', () => {})">
        {{ $t('authentication.return-portal') }}
      </el-button>
    </div>
  </div>
</template>

<script>
import sdk from '@megaport/api-sdk'
/*
 * This component handles the ChangeEmail flow, started from the User Profile section.
 * the /verifyEmail route will be called from the email sent as part of that flow, which
 * redirects to here and calls /email/verify endpoint, which updates the users email
 */
export default {
  name: 'ChangeEmail',

  data() {
    return {
      state: 'waiting',
      hash: null,
    }
  },

  created() {
    this.verifyEmail()
  },

  methods: {
    verifyEmail() {
      sdk.instance
        .profile()
        .verifyEmail(this.$route.hash.replace('#', ''))
        .then(() => {
          this.state = 'success'
        })
        .catch(() => {
          this.state = 'failed'
        })
    },
  },
}
</script>

<style lang="scss" scoped>
.content-panel {
  background-color: var(--color-white);
  border: 1px solid var(--card-border-color);
  border-radius: var(--border-radius-base);
  padding: 1rem;
  margin: auto;
  width: fit-content;
  min-width: 420px;
  text-align: center;
}
</style>
